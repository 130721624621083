import React from "react";
import { CssBaseline, Box, Typography, Button, IconButton, Paper } from '@mui/material';
import imagebackground from "../../images/1.jpg"; // Replace with your background image
import employee1 from "../../images/new/Group 83.png"; // Replace with actual path of employee image 1
import employee2 from "../../images/new/Group 82.png"; // Replace with actual path of employee image 2
import newsImage1 from "../../images/new/Group 80.png"; // Replace with path of news image 1
import newsImage2 from "../../images/new/Group 86.png"; // Replace with path of news image 2
import { FaSkype, FaTwitter, FaWhatsapp, FaFacebook, FaInstagram, FaSnapchat, FaUserCircle } from "react-icons/fa";
import ThreeDivsSection from "./ThreeDivsSection"; // Import the ThreeDivsSection component
import bomaNewsImage  from "../../images/new/bomagdedaaaaa.png"
import { useNavigate } from "react-router-dom";
const PublicHome = () => {
const navigate = useNavigate()

  const handleClick = ()=>{
    navigate('/login')
  }
  return (
    <div style={{
      width: "100%",
      height: "100vh", // Ensure the entire page is 100vh
      backgroundImage: `url(${imagebackground})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      display: "flex",
      flexDirection: "column",
    }}>
      <CssBaseline />

      <Box style={{
        flex: 1, // Occupy available space in flexbox
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
        padding: "20px", // Add some padding for spacing
        overflow: "hidden",
      }}>

        {/* Center Section: Welcome Message */}
        <Typography variant="h3" style={{ fontWeight: "bold", color: "white",paddingTop:"500px"}}>
          Welcome to Our Family
        </Typography>
        <Typography variant="h5" style={{ color: "white", marginBottom: "30px" }}>
          INFO@<span style={{color:"#00ffa9"}}>ZBOOMA</span>.COM
        </Typography>

        {/* Left Section: Social Media Icons */}
        <Box style={{
          position: "absolute",
          top: "40%",
          left: "20px",
          transform: "translateY(-50%)",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}>
          <IconButton style={{ color: "#00ffa9" }}><FaWhatsapp size={30} /></IconButton>
          <IconButton style={{ color: "#00ffa9" }}><FaTwitter size={30} /></IconButton>
          <IconButton style={{ color: "#00ffa9" }}><FaFacebook size={30} /></IconButton>
          <IconButton style={{ color: "#00ffa9" }}><FaInstagram size={30} /></IconButton>
          <IconButton style={{ color: "#00ffa9" }}><FaSnapchat size={30} /></IconButton>
          <IconButton style={{ color: "#00ffa9" }}><FaSkype size={30} /></IconButton>
        </Box>

        {/* Right Section: Login and Register Buttons */}
        <Box style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          display: "flex",
          gap: "10px",
        }}>
          <Button onClick={handleClick} variant="contained" style={{ backgroundColor: "#00C853", color: "white",fontFamily:"cairo"}}>تسجيل دخول</Button>
          <Button variant="outlined" style={{ borderColor: "#00C853", color: "#00C853",fontFamily:"cairo"}}>تسجيل خروج</Button>
          <IconButton style={{ color: "#00C853" }}>
            <FaUserCircle size={30} />
          </IconButton>
        </Box>

        {/* Left Section: News Boxes with Background Images */}
        <Box style={{
    position: "absolute",
    top: "70%", // Position it lower on the page
    left: "10%",
    transform: "translateY(-50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Center the items
    gap: "20px",
    backgroundColor: "#25322b",
    borderRadius: "20px",
    padding: "20px",
    width: "300px",
    color: "white",
    zIndex: 1, // Ensure the box appears below the image
}}>
    {/* Boma News Image Positioned Absolutely */}
    <Box style={{
        position: "absolute",
        top: "-50px", // Position image above the box
        transform: "translateY(-50%)",
        zIndex: 2, // Ensure the image appears above the box
    }}>
        <img src={bomaNewsImage} alt="Boma News" style={{ maxWidth: "100px", borderRadius: "50%", backgroundColor: "#25322b", padding: "5px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }} />
    </Box>

    {/* News Content */}
    <Typography variant="h5" style={{ color: "#00ffa9", textAlign: "center" }}>
        OUR NEWS TODAY
    </Typography>
    <Typography variant="body1" style={{ textAlign: "center", fontSize: "15px" }}>
        We Are Celebrating Several Years Since The Establishment Of Our Company As Its Main Principle. Since Its Success Years Ago, It Has Achieved Great Achievements In Many Fields, Making It One Of The Leading Companies In Providing Innovative Software Solutions.
    </Typography>
</Box>


        {/* Right Section: Best Employees */}
        <Box style={{
          position: "absolute",
          top: "65%",
          right: "10%",
          transform: "translateY(-50%)",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}>
          {/* Employee Card 1 */}
          <Paper style={{
            background: "#2C2C2C",
            borderRadius: "50px",
            color: "white",
            width: "350px",
            height: "130px", // Adjusted height
            display: "flex",
            alignItems: "stretch", // Full height of the image
            justifyContent: "space-between",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)"
          }}>
            <Box style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              justifyContent: "center",
              padding: "0 20px",
            }}>
              <Typography  variant="h6">The Best Employee</Typography>
              <Typography variant="body1" style={{ color: "#00ffa9" }}>Muhammad Adel</Typography>
            </Box>
            <Box style={{
              height: "100%",
              overflow: "hidden",
              flexShrink: 0,
            }}>
              <img src={employee1} alt="Muhammad Adel" style={{ height: "100%", borderRadius: "0 50px 50px 0", margin: 0 }} />
            </Box>
          </Paper>

          {/* Employee Card 2 */}
          <Paper style={{
            background: "#2C2C2C",
            borderRadius: "50px",
            color: "white",
            width: "350px",
            height: "130px", // Adjusted height
            display: "flex",
            alignItems: "stretch", // Full height of the image
            justifyContent: "space-between",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)"
          }}>
            <Box style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              justifyContent: "center",
              padding: "0 20px",
            }}>
              <Typography variant="h6">The Best Employee</Typography>
              <Typography variant="body1" style={{ color: "#00ffa9" }}>Al-Shaima Usri</Typography>
            </Box>
            <Box style={{
              height: "100%",
              overflow: "hidden",
              flexShrink: 0,
            }}>
              <img src={employee2} alt="Al-Shaima Usri" style={{ height: "100%", borderRadius: "0 50px 50px 0", margin: 0 }} />
            </Box>
          </Paper>
        </Box>
      </Box>

      {/* Bottom Section: ThreeDivsSection */}
      <Box style={{ flexShrink: 0, height: "38vh", overflow: "hidden" }}>
        <ThreeDivsSection />
      </Box>
    </div>
  );
}

export default PublicHome;
