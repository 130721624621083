import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  Box,
  LinearProgress,
  Avatar,
  Button,
  Menu,
  MenuItem,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem as SelectItem,
} from "@mui/material";
import { styled } from "@mui/system";
import WebIcon from "@mui/icons-material/Web";
import BrushIcon from "@mui/icons-material/Brush";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import CodeIcon from "@mui/icons-material/Code";
import StorageIcon from "@mui/icons-material/Storage";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import LeadershipIcon from "@mui/icons-material/EmojiEvents"; // Example additional icon
import CommunicationIcon from "@mui/icons-material/Forum"; // Example additional icon
import CreativityIcon from "@mui/icons-material/Lightbulb"; // New skill icon
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"; // Default icon for new skills
import Swal from "sweetalert2";

// Define initial available skills
const initialAvailableSkills = [
  { name: "WordPress", icon: <WebIcon /> },
  { name: "Graphic Design", icon: <BrushIcon /> },
  { name: "React", icon: <DeveloperModeIcon /> },
  { name: "Node.js", icon: <CodeIcon /> },
  { name: "PHP", icon: <StorageIcon /> },
  { name: "SALLA", icon: <WebIcon /> },
];

// Define initial available discipline skills
const initialAvailableDisciplineSkills = [
  { name: "حضوره", icon: <EmojiPeopleIcon /> },
  { name: "التزام الوقت", icon: <AccessTimeIcon /> },
  { name: "التعاون", icon: <ThumbUpIcon /> },
  { name: "قيادة الفريق", icon: <LeadershipIcon /> },
  { name: "التواصل", icon: <CommunicationIcon /> },
  { name: "الإبداع", icon: <CreativityIcon /> },
];

// Styled components
const SkillIcon = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(2),
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.2)",
  },
}));

const AnimatedProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20, // Thicker sliders
  borderRadius: 5,
  backgroundColor: "#e0e0e0",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#6fd943",
  },
}));

const TeamLeaderSkills = ({ user }) => {
  const [availableSkills, setAvailableSkills] = useState(initialAvailableSkills);
  const [availableDisciplineSkills, setAvailableDisciplineSkills] = useState(
    initialAvailableDisciplineSkills
  );
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [skillPercentages, setSkillPercentages] = useState({});
  const [disciplinePercentages, setDisciplinePercentages] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("skills"); // 'skills' or 'discipline'
  const [openDialog, setOpenDialog] = useState(false); // For the add skill dialog
  const [newSkillName, setNewSkillName] = useState("");
  const [newSkillCategory, setNewSkillCategory] = useState("skills"); // Default to technical skills

  useEffect(() => {
    if (user && user.role === "Team Leader") {
      fetchEmployees();
    }
  }, [user]);

  const fetchEmployees = async () => {
    try {
      const response = await fetch(
        "https://backendforerpsystem.zbooma.com/getemployees.php"
      );
      const result = await response.json();
      if (result.status === "success") {
        const filteredEmployees = result.data.filter(
          (employee) =>
            employee.department2 === user.department2 &&
            employee.role !== "Team Leader"
        );
        setEmployees(filteredEmployees);

        // Initialize skill percentages for each employee
        const initialSkillPercentages = {};
        const initialDisciplinePercentages = {};
        filteredEmployees.forEach((emp) => {
          initialSkillPercentages[emp.employee_id] = {};
          initialDisciplinePercentages[emp.employee_id] = {};
          fetchSkills(emp.employee_id);
          fetchDisciplineSkills(emp.employee_id);
        });
        setSkillPercentages(initialSkillPercentages);
        setDisciplinePercentages(initialDisciplinePercentages);
      } else {
        setError("فشل في جلب بيانات الموظفين: " + result.message);
      }
    } catch (error) {
      setError("خطأ في جلب بيانات الموظفين: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchSkills = async (employeeId) => {
    try {
      const response = await fetch(
        `https://backendforerpsystem.zbooma.com/getSkills.php?employeeId=${employeeId}`
      );
      const result = await response.json();
      if (result.success) {
        setSkillPercentages((prev) => ({
          ...prev,
          [employeeId]: result.skills.reduce((acc, skill) => {
            acc[skill.skill_name] = skill.percentage;
            return acc;
          }, {}),
        }));
      } else {
        console.error("Failed to fetch skills:", result.message);
        setError(`Failed to fetch skills: ${result.message || "unknown error"}`);
      }
    } catch (error) {
      console.error("خطأ في جلب المهارات: ", error.message);
      setError("خطأ في جلب المهارات: " + error.message);
    }
  };

  const fetchDisciplineSkills = async (employeeId) => {
    try {
      const response = await fetch(
        `https://backendforerpsystem.zbooma.com/getDisciplineSkills.php?employeeId=${employeeId}`
      );
      const result = await response.json();
      if (result.success) {
        setDisciplinePercentages((prev) => ({
          ...prev,
          [employeeId]: result.skills.reduce((acc, skill) => {
            acc[skill.skill_name] = skill.percentage;
            return acc;
          }, {}),
        }));
      } else {
        console.error("Failed to fetch discipline skills:", result.message);
        setError(`Failed to fetch discipline skills: ${result.message || "unknown error"}`);
      }
    } catch (error) {
      console.error("خطأ في جلب المهارات الانضباطية: ", error.message);
      setError("خطأ في جلب المهارات الانضباطية: " + error.message);
    }
  };

  const handleAddSkillClick = (event, employee, category) => {
    setSelectedEmployee(employee);
    setSelectedCategory(category);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedSkill(null);
  };

  const handleSkillSelect = (skill) => {
    setSelectedSkill(skill);
    if (selectedEmployee) {
      const currentPercentage =
        (selectedCategory === "skills"
          ? skillPercentages
          : disciplinePercentages)[selectedEmployee.employee_id][skill.name] ||
        0;
      const setPercentages =
        selectedCategory === "skills"
          ? setSkillPercentages
          : setDisciplinePercentages;
      setPercentages((prev) => ({
        ...prev,
        [selectedEmployee.employee_id]: {
          ...prev[selectedEmployee.employee_id],
          [skill.name]: currentPercentage,
        },
      }));
    }
  };

  const handleSkillChange = (value) => {
    if (selectedEmployee && selectedSkill) {
      const percentage = Number(value);
      const setPercentages =
        selectedCategory === "skills"
          ? setSkillPercentages
          : setDisciplinePercentages;
      setPercentages((prev) => ({
        ...prev,
        [selectedEmployee.employee_id]: {
          ...prev[selectedEmployee.employee_id],
          [selectedSkill.name]: percentage,
        },
      }));
    }
  };

  const handleAddSkill = async () => {
    if (selectedEmployee && selectedSkill) {
      const percentage =
        (selectedCategory === "skills"
          ? skillPercentages
          : disciplinePercentages)[selectedEmployee.employee_id][
          selectedSkill.name
        ];
      if (percentage >= 0) {
        try {
          const endpoint =
            selectedCategory === "skills"
              ? "https://backendforerpsystem.zbooma.com/addSkill.php"
              : "https://backendforerpsystem.zbooma.com/addDisciplineSkill.php";

          const response = await fetch(endpoint, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              employeeId: selectedEmployee.employee_id,
              skillName: selectedSkill.name,
              percentage,
            }),
          });
          const result = await response.json();
          if (result.success) {
            Swal.fire({
              icon: "success",
              title: "تمت الإضافة",
              text: "تم إضافة المهارة بنجاح",
              confirmButtonColor: "#6fd943",
            });
            fetchEmployees(); // Refresh the employees list
            handleMenuClose();
          } else {
            Swal.fire({
              icon: "error",
              title: "خطأ",
              text: "فشل في إضافة المهارة: " + result.message,
              confirmButtonColor: "#d33",
            });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: "خطأ في إضافة المهارة: " + error.message,
            confirmButtonColor: "#d33",
          });
        }
      }
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewSkillName("");
  };

  const handleAddNewSkill = () => {
    if (newSkillName.trim()) {
      const newSkill = { name: newSkillName, icon: <AddCircleOutlineIcon /> };
      if (newSkillCategory === "skills") {
        setAvailableSkills((prevSkills) => [...prevSkills, newSkill]);
      } else {
        setAvailableDisciplineSkills((prevSkills) => [
          ...prevSkills,
          newSkill,
        ]);
      }
      Swal.fire({
        icon: "success",
        title: "تمت الإضافة",
        text: "تمت إضافة المهارة بنجاح",
        confirmButtonColor: "#6fd943",
      });
      handleCloseDialog();
    } else {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        text: "يرجى إدخال اسم المهارة",
        confirmButtonColor: "#d33",
      });
    }
  };

  if (loading) {
    return (
      <Box style={{ padding: "24px", textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box style={{ padding: "24px", textAlign: "center" }}>
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      style={{
        padding: "24px",
        fontFamily: "Cairo, sans-serif",
        textAlign: "right",
        direction: "rtl",
      }}
    >
      <Typography
        variant="h4"
        style={{
          color: "#6fd943",
          marginBottom: "16px",
          fontFamily: "Cairo, sans-serif",
        }}
      >
        مهارات الموظفين
      </Typography>
      <Button
        variant="contained"
        style={{
          backgroundColor: "#6fd943",
          color: "#fff",
          fontFamily: "Cairo, sans-serif",
          marginBottom: "16px",
        }}
        onClick={handleOpenDialog}
      >
        إضافة مهارة جديدة
      </Button>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>إضافة مهارة جديدة</DialogTitle>
        <DialogContent>
          <TextField
            label="اسم المهارة"
            fullWidth
            value={newSkillName}
            onChange={(e) => setNewSkillName(e.target.value)}
            style={{ marginBottom: "16px" }}
          />
          <Select
            label="نوع المهارة"
            value={newSkillCategory}
            onChange={(e) => setNewSkillCategory(e.target.value)}
            fullWidth
            style={{ marginBottom: "16px" }}
          >
            <SelectItem value="skills">مهارة تقنية</SelectItem>
            <SelectItem value="discipline">مهارة انضباطية</SelectItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            إلغاء
          </Button>
          <Button onClick={handleAddNewSkill} color="primary">
            إضافة
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        {employees.map((employee, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Paper
              style={{
                padding: "16px",
                borderRadius: "10px",
                boxShadow: "0 4px 16px rgba(0,0,0,0.1)",
              }}
            >
              <Box display="flex" alignItems="center" mb={2}>
                <Avatar
                  alt={employee.name}
                  src={`https://backendforerpsystem.zbooma.com/${employee.photo}`}
                  style={{ width: "60px", height: "60px", marginLeft: "16px" }}
                />
                <Typography
                  variant="h6"
                  style={{ fontFamily: "Cairo, sans-serif" }}
                >
                  {employee.name}
                </Typography>
              </Box>
              {employee.skills &&
                Object.entries(skillPercentages[employee.employee_id] || {}).map(
                  ([skillName, percentage], skillIndex) => (
                    <Box
                      key={skillIndex}
                      display="flex"
                      alignItems="center"
                      mb={2}
                    >
                      <SkillIcon>
                        {availableSkills.find((s) => s.name === skillName)?.icon}
                      </SkillIcon>
                      <Typography
                        variant="body1"
                        style={{
                          fontFamily: "Cairo, sans-serif",
                          marginBottom: "4px",
                          color: "gray",
                          width: "120px",
                          flexShrink: 0,
                        }}
                      >
                        {skillName}
                      </Typography>
                      <Box flexGrow={1} mx={2}>
                        <AnimatedProgress
                          variant="determinate"
                          value={percentage}
                        />
                      </Box>
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Cairo, sans-serif",
                          color: "gray",
                          marginTop: "4px",
                        }}
                      >
                        {percentage}%
                      </Typography>
                    </Box>
                  )
                )}
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#6fd943",
                  color: "#fff",
                  fontFamily: "Cairo, sans-serif",
                  marginTop: "8px",
                }}
                onClick={(event) => handleAddSkillClick(event, employee, "skills")}
              >
                اضافة مهارة تقنية 
              </Button>
              {employee.disciplineSkills &&
                Object.entries(
                  disciplinePercentages[employee.employee_id] || {}
                ).map(([skillName, percentage], skillIndex) => (
                  <Box
                    key={skillIndex}
                    display="flex"
                    alignItems="center"
                    mb={2}
                  >
                    <SkillIcon>
                      {availableDisciplineSkills.find(
                        (s) => s.name === skillName
                      )?.icon}
                    </SkillIcon>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Cairo, sans-serif",
                        marginBottom: "4px",
                        color: "gray",
                        width: "120px",
                        flexShrink: 0,
                      }}
                    >
                      {skillName}
                    </Typography>
                    <Box flexGrow={1} mx={2}>
                      <AnimatedProgress
                        variant="determinate"
                        value={percentage}
                      />
                    </Box>
                    <Typography
                      variant="body2"
                      style={{
                        fontFamily: "Cairo, sans-serif",
                        color: "gray",
                        marginTop: "4px",
                      }}
                    >
                      {percentage}%
                    </Typography>
                  </Box>
                ))}
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#6fd943",
                  color: "#fff",
                  fontFamily: "Cairo, sans-serif",
                  marginTop: "8px",
                  marginRight: "8px",
                }}
                onClick={(event) => handleAddSkillClick(event, employee, "discipline")}
              >
                إضافة مهارة انضباطية
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {(selectedCategory === "skills" ? availableSkills : availableDisciplineSkills).map(
          (skill, index) => (
            <MenuItem key={index} onClick={() => handleSkillSelect(skill)}>
              {skill.icon}
              <Typography style={{ marginLeft: "8px" }}>{skill.name}</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={
                  (selectedEmployee &&
                    (selectedCategory === "skills"
                      ? skillPercentages[selectedEmployee.employee_id]
                      : disciplinePercentages[selectedEmployee.employee_id])[
                      skill.name
                    ]) || ""
                }
                onChange={(e) => handleSkillChange(e.target.value)}
                inputProps={{ min: 0, max: 100, style: { marginLeft: "8px" } }}
                placeholder="النسبة"
              />
              <Button
                variant="outlined"
                style={{ marginLeft: "8px", fontFamily: "Cairo, sans-serif" }}
                onClick={handleAddSkill}
              >
                حفظ
              </Button>
            </MenuItem>
          )
        )}
      </Menu>
    </Box>
  );
};

export default TeamLeaderSkills;
