// Create a new context file: UnreadMessagesContext.js
import React, { createContext, useState, useContext } from 'react';

const UnreadMessagesContext = createContext();

export const useUnreadMessages = () => useContext(UnreadMessagesContext);

export const UnreadMessagesProvider = ({ children }) => {
    const [unreadCount, setUnreadCount] = useState(0);

    const updateUnreadCount = (count) => {
        setUnreadCount(count);
    };

    return (
        <UnreadMessagesContext.Provider value={{ unreadCount, updateUnreadCount }}>
            {children}
        </UnreadMessagesContext.Provider>
    );
};
