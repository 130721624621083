// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HrDashboard from './Pages/DashboardHr/HRDashboard';
import PrivateRoute from './Components/PrivateRoute';
import Login from "./Pages/Login/Login.jsx";
import EmployeeDetail from './Components/EmployeeDetail/EmployeeDetail.jsx';
import UserDashboardSidebar from './Pages/DashboardUser/DashBoardUser.jsx';
import PrivateRoutEmployee from './Components/PrivateRouteEnployee.jsx';
import GetHomeAds from './Pages/GetHomeAds/GetHomeAds.jsx';
import HomeTest from './Pages/HomeTest/HomeTest.jsx';
import PublicHome from './Pages/PuplicHome/PuplicHome.jsx';
import { UnreadMessagesProvider } from './Components/UnreadMessagesContext.js';

const App = () => {
  return (
    <UnreadMessagesProvider>  {/* Wrap the router with the context provider */}
      <Router>
        <Routes>
          <Route path="/" element={<PublicHome />} />
          <Route path="/login" element={<Login />} />
          <Route path="/employee/:id" element={<EmployeeDetail />} />
          <Route path="/hr-dashboard" element={<PrivateRoute><HomeTest /></PrivateRoute>} />
          <Route path="/hometest" element={<HomeTest />} />
          <Route path="/employee-dashboard" element={<PrivateRoutEmployee><UserDashboardSidebar /></PrivateRoutEmployee>} />
        </Routes>
      </Router>
    </UnreadMessagesProvider>
  );
};

export default App;
