import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import centerImage from "../../images/new/Group 69.png"; // Replace this path with the actual path to your uploaded image
import image2 from "../../images/new/Group 76.png";
import image3 from "../../images/new/Group 77.png";
import Ceo from "../../images/new/Group 73.png";
import image4 from "../../images/new/Group 74.png";
import image5 from "../../images/new/Group 75.png";
import gm from "../../images/new/Group 71.png";
import zbooma from "../../images/new/Group 88.png";

const ThreeDivsSection = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000); // Update time every second
    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, []);

  return (
    <div
      style={{
        width: "80%", // Container width set to 80% of the screen
        margin: "0 auto", // Center the container
        display: "flex",
        justifyContent: "space-between", // Evenly space between sections
        boxSizing: "border-box",
        gap: "40px",
        marginTop: "30px"
      }}
    >
      {/* Left Section */}
      <Box
        style={{
          width: "29%", // Right section width
          padding: "10px",
          display: "flex",
          flexDirection: "column", // Column layout for sub-sections
          gap: "10px",
        }}
      >
        {/* Right Top Section */}
        <Box
          style={{
            paddingTop: "10px",
            backgroundColor: "#25322b",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center", // Center items horizontally and vertically
          }}
        >
          <img src={image3} alt="Image 3" style={{ marginRight: "10px" }} /> {/* Optional margin for spacing */}
          <img src={image2} alt="Image 2" />
        </Box>

        {/* Right Bottom Section */}
        <Box style={{ padding: "10px", display: "flex", alignItems: "flex-end" }}>
          {/* Image Container */}
          <div>
            <img src={gm} alt="gmImage" style={{ maxWidth: "170px" }} />
          </div>

          {/* Text Container */}
          <div style={{ display: "flex", flexDirection: "column", alignSelf: "flex-end", paddingBottom: "10px" }}>
            <h2 style={{ margin: 0, color: "#07f4a5", textAlign: "center", fontWeight: "800" }}>G M</h2>
            <p style={{ margin: 0, color: "white", fontSize: "15px" }}>Mohamed Mounier</p>
          </div>
        </Box>
      </Box>

      {/* Center Section */}
      <Box
        style={{
          width: "45%", // Center section takes up more space
          borderRadius: "25px",
          padding: "10px",
          backgroundColor: "#1a2623",
          display: "flex",
          flexDirection: "column", // Column layout for image and text
          justifyContent: "center",
          alignItems: "center",
          gap: "20px", // Space between image and text
        }}
      >
        {/* Center Box with Image and Sentence */}
        <Box
          style={{
            display: "flex",
            flexDirection: "row", // Arrange image and text side by side
            alignItems: "center",
            gap: "20px", // Gap between image and text
            width: "100%",
            justifyContent: "center", // Center contents within the box
          }}
        >
          {/* Text Box */}
          <Box style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h4" style={{ color: "#fff", textAlign: "center" }}>
              <span style={{ fontWeight: "800" }}> WE ALL</span>
              <br />
              BELONG TO
              <br />
              <img src={zbooma} style={{ maxWidth: "130px" }} alt="Zbooma" />
            </Typography>

            {/* Timer Below the Typography */}
            <Typography variant="h5" style={{ color: "#00ffa9", textAlign: "center", marginTop: "10px",border:"1px solid #00f88b",padding:"5px",borderRadius:"5px"}}>
              {currentTime.toLocaleTimeString()} {/* Display the current time */}
            </Typography>
          </Box>

          {/* Center Image */}
          <Box
            style={{
              flexShrink: 0, // Prevent shrinking of image
              width: "60%", // Adjust the image box width
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={centerImage} // Use the imported image path here
              alt="Center Section"
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "420px", // Limit image width to control its size
                borderRadius: "10px",
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* Right Section */}
      <Box
        style={{
          width: "25%", // Right section width
          padding: "10px",
          display: "flex",
          flexDirection: "column", // Column layout for sub-sections
          gap: "10px",
        }}
      >
        {/* Right Top Section */}
        <Box
          style={{
            paddingTop: "10px",
            backgroundColor: "#25322b",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center", // Center items horizontally and vertically
          }}
        >
          <img src={image5} alt="Image 3" style={{ marginRight: "10px" }} /> {/* Optional margin for spacing */}
          <img src={image4} alt="Image 2" />
        </Box>

        {/* Right Bottom Section */}
        <Box style={{ padding: "10px", display: "flex" }}>
          <div>
            <img src={Ceo} alt="ceoImage" style={{ maxWidth: "130px" }} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignSelf: "flex-end", marginLeft: "10px", paddingBottom: "10px" }}>
            <h2 style={{ margin: 0, textAlign: "center", color: "#07f4a5", fontWeight: "800" }}>C E O</h2>
            <p style={{ margin: 0, color: "white", fontSize: "13px" }}>SAMI EL-MATWALYE</p>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default ThreeDivsSection;
